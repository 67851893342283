<template>
  <div class="creat_bg">
    <div class="animate__animated animate__fadeInDown" ref="slider">
      <div
          class="creat_close"
          @click="
          handleclose();
          playAudio();
        "
      >
        <svg
            t="1708678106448"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2989"
        >
          <path
              d="M898.844444 830.577778c22.755556 17.066667 22.755556 51.2 0 73.955555s-51.2 22.755556-73.955555 0L512 591.644444l-312.888889 312.888889c-11.377778 11.377778-22.755556 17.066667-39.822222 17.066667-11.377778 0-28.444444-5.688889-39.822222-17.066667-22.755556-22.755556-22.755556-51.2 0-73.955555l312.888889-312.888889-307.2-318.577778c-11.377778-5.688889-17.066667-22.755556-17.066667-34.133333 0-11.377778 5.688889-28.444444 17.066667-34.133334 22.755556-22.755556 51.2-22.755556 73.955555 0L512 443.733333l312.888889-312.888889c22.755556-22.755556 51.2-22.755556 73.955555 0s22.755556 51.2 0 73.955556l-312.888888 312.888889 312.888888 312.888889z"
              fill="#727477"
              p-id="2990"
          />
        </svg>
      </div>
      <div class="title">添加客服QQ</div>

      <div class="kami">
        <div class="box">
          <img src="qqQR.png" alt="" />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {} from "@/api/index";
export default {
  data() {
    return {};
  },

  mounted() {},
  computed: {},
  methods: {
    playAudio() {
      this.$store.commit("playAudio");
    },
    downloadAnd() {

      // const blob = new Blob(['http://www.mkcsgo.com/download/MKCSGO.apk'],{ type: 'application/zip' })
      // const fileName = 'MKCSGO'
      // const link = document.createElement('a')
      // link.download = fileName
      // link.style.display = 'none'
      // link.href = URL.createObjectURL(blob)
      // document.body.appendChild(link)
      // link.click()
      // URL.revokeObjectURL(link.href)
      // document.body.removeChild(link)
      window.open("http://www.mkcsgo.com/download/MKCSGO.apk");

    },
    downloadIos() {
      window.open("http://www.mkcsgo.com/download/MKCSGOAPPLE.mobileconfig");
    },
    handleclose() {
      const element = this.$refs.slider;
      element.classList += " animate__fadeOutDown";
      setTimeout(() => {
        this.$router.back();
      }, 350);
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 100px;
  margin-top: 100px;
  img {
    width: 400px;
    height: 400px;
  }
}
.title {
  font-size: 25px;
  font-weight: bold;
  margin-top: 100px;
}
.kami {
  margin-top: 14px;
  width: 100%;
  background: #101115;
  border-radius: 20px;
  //   margin-top: 100px;
  overflow: hidden;
  // @media (max-width: 550px) {
  //   height: 40px;
  // }
}
.kami_input {
  width: 100%;
  font-size: 21px;

  height: 100%;
  background-color: rgba(0, 0, 0, 0.26);
  color: #fff;
  text-align: center;
  outline: none;
  // @media (max-width: 550px) {
  //   height: 40px;
  //   font-size: 15px;
  // }
}
.kami_input::-webkit-input-placeholder {
  color: #fff;
}
.creat_bg {
  position: fixed;
  top: 0;
  left: 50%;
  // margin-left: -262.5px;
  width: 525px;
  transform: translateX(-50%);
  height: 100vh;
  z-index: 20;
  background: rgba(0, 0, 0, 0.85);
  padding: 2% 20px;
  @media (max-width: 550px) {
    width: 100%;
  }
  .creat_close {
    width: 100%;
    text-align: right;
    position: relative;
    height: 25px;

    .icon {
      position: absolute;
      right: 10px;
      margin: 0;
      width: 22px;
      height: 22px;
      @media (max-width: 550px) {
        width: 16px;
        height: 16px;
      }
    }
  }
  .fjrs {
    @media (max-width: 550px) {
      font-size: 15px;
    }
  }

  .cerate_btn {
    width: 93.5%;
    height: 56px;
    margin: 0 auto;
    margin-top: 14px;
    background: linear-gradient(
            360deg,
            rgba(37, 244, 132, 0.2) 0%,
            rgba(37, 244, 132, 0.1) 100%
    );
    opacity: 1;
    border: 1px solid #25f484;
    font-size: 25px;
    @media (max-width: 550px) {
      font-size: 18px;
      height: 40px;
    }
  }
}
.result {
  margin: 0 auto;
  width: 476px;
  padding: 32px 0;
  background: #101115;
  border-radius: 23px 23px 23px 23px;
  opacity: 1;
  position: fixed;
  top: 30%;
  left: 50%;
  margin-left: -238px;
  z-index: 31;
  @media (max-width: 550px) {
    width: 340px;
    margin-left: -170px;
    padding: 25px 0;
  }
  .lvbu {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    @media (max-width: 550px) {
      height: 110px;
    }
    border-radius: 23px 23px 23px 23px;
    height: 130px;
    background: linear-gradient(
            -218deg,
            rgba(37, 244, 132, 0.2) 0%,
            rgba(32, 29, 48, 0) 38%,
            rgba(248, 125, 81, 0) 100%
    );
  }
  .result_title {
    width: 100%;
    text-align: center;
    font-size: 28px;
    color: #ffff;
    @media (max-width: 550px) {
      font-size: 20px;
    }
  }
  .result_center {
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .result_money {
    width: 90%;
    height: 56px;
    // background: #323436;
    border-radius: 35px 35px 35px 35px;
    opacity: 1;
    margin: 0 auto;
    margin-top: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    // color: #25f484;
    @media (max-width: 550px) {
      height: 40px;
    }
  }
  .result_btn {
    width: 100%;
    margin: 14px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 21px;

    .result_btn_B {
      width: 196px;
      height: 56px;
      background: linear-gradient(
              360deg,
              rgba(37, 244, 132, 0.5) 0%,
              rgba(37, 244, 132, 0.25) 100%
      );
      opacity: 1;
      border: 2px solid #25f484;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 550px) {
        width: 140px;
        height: 30px;
        font-size: 15px;
      }
    }
  }
}
</style>
